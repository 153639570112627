import React, { useState } from 'react';
import { ResumeLayout } from '../Layouts';
import { StepTracker2 } from '../common/StepTracker';
import {
  HorizontalResumeTab,
  VerticalResumeTab,
} from '../common/Tabs';
import ResumeForm from '../common/Forms/ResumeForm';
import { useSelector, useDispatch } from 'react-redux';
import { projectSlice, authSlice } from '../../store';
import {
  useCreateResumeMutation,
  useUpdateResumeMutation,
  useGetScoreMutation,
} from '../../store';
import { SpinnerLoader } from '../common/Loader';
import { useNavigate } from 'react-router-dom';
import resumeTabs from '../../constants/resumeTabs';
import styled from 'styled-components';
import { fetchTask } from '../../utils/fetchTask';
import { useAuth0 } from '@auth0/auth0-react';

const CreateOrUpdateResume = ({ resume }) => {
  const { resumeTitle } = useSelector((state) => state.project);
  const [selectedTab, setSelectedTab] = useState('section1');
  const [data, setData] = useState({
    ...resume,
    title: resumeTitle || resume?.title,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [createResume, { data: resumeData, isLoading, error }] =
    useCreateResumeMutation();
  const [
    updateResume,
    {
      data: editedResume,
      isLoading: editIsLoading,
      error: editIseError,
    },
  ] = useUpdateResumeMutation();

  const createResumeAndContinue = async () => {
    dispatch(projectSlice.actions.setResumeObj(data));
    const jsonData = JSON.stringify(data);
    await createResume(jsonData);
  };

  const editResumeAndContinue = async () => {
    dispatch(projectSlice.actions.setResumeObj(data));
    dispatch(projectSlice.actions.setActiveResume(data));
    const jsonData = JSON.stringify(data);
    await updateResume({
      id: data.id,
      resume: { content: jsonData, title: resumeTitle },
    });
  };

  const saveAndContinue = data.id
    ? editResumeAndContinue
    : createResumeAndContinue;

  if (error || editIseError) return <div>{error.message}</div>;

  if (resumeData) {
    dispatch(projectSlice.actions.setResumeId(resumeData.resume.id));
    const resume = resumeData.resume;
    const content = JSON.parse(resume.content);
    dispatch(
      projectSlice.actions.setResumeObj({ content, ...resume })
    );
    dispatch(
      projectSlice.actions.setActiveResume({ content, ...resume })
    );
    navigate('/user-profile');
  }

  if (editedResume) {
    dispatch(
      projectSlice.actions.setResumeId(editedResume.resume.id)
    );
    const resume = editedResume.resume;
    const content = JSON.parse(resume.content);

    dispatch(
      projectSlice.actions.setResumeObj({ content, ...resume })
    );
    dispatch(
      projectSlice.actions.setActiveResume({ content, ...resume })
    );
    navigate('/user-profile');
  }

  const handleTabClick = (ref) => {
    setSelectedTab(ref);
  };

  return (
    <div className="w-full max-h-screen lg:overflow-y-auto hide-scrollbar">
      <ResumeLayout
        VerticalResumeTab={
          <VerticalResumeTab
            selectedTab={selectedTab}
            tabs={resumeTabs}
            handleTabClick={handleTabClick}
          />
        }
        HorizontalResumeTab={
          <HorizontalResumeTab
            selectedTab={selectedTab}
            tabs={resumeTabs}
            handleTabClick={handleTabClick}
          />
        }
        ResumeForm={
          <ResumeForm
            selectedTab={selectedTab}
            data={data}
            setData={setData}
          />
        }
        NextStep={
          <EditOrCreateResume
            saveAndContinue={saveAndContinue}
            data={data}
          />
        }
      />
      {(isLoading || editIsLoading) && (
        <SpinnerLoader text={'Saving your Resume...'} />
      )}
    </div>
  );
};

const NextStepWrapper = styled.div`
  background: #fff;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.04);

  @media (min-width: 1024px) {
    background-color: transparent;
    box-shadow: none;
  }
`;

const EditOrCreateResume = ({ saveAndContinue, data }) => {
  const [loading, setLoading] = useState(false);
  const [taskFailed, setTaskFailed] = useState(false);
  const { fromHomePage, job, activeResume } = useSelector(
    (state) => state.project
  );
  const { createResumePermission } = useSelector(
    (state) => state.auth
  );
  const { getAccessTokenSilently } = useAuth0();

  const [getScore] = useGetScoreMutation();
  const [createResume] = useCreateResumeMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const createResumeFromHomePage = async () => {
    const jsonData = JSON.stringify(data);
    const res = await createResume(jsonData);

    setLoading(true);
    const resume = res.data.resume;
    if (!resume) return setTaskFailed(true);

    const content = JSON.parse(resume.content);

    dispatch(projectSlice.actions.setResumeId(resume.id));

    dispatch(
      projectSlice.actions.setResumeObj({ content, ...resume })
    );
    dispatch(
      projectSlice.actions.setActiveResume({ content, ...resume })
    );
    navigate('/user-profile');
    // handleGetScore(resume.id);
  };

  const handleGetScore = async (id) => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://www.revats.ai/`,
        scope: 'read:current_user',
      });
      dispatch(authSlice.actions.setAccessToken(accessToken));
      // @TODO Check Permission
      // if (!createResumePermission) {
      //   setUpgradeModalIsOpen(true);
      //   return;
      // }

      const data = { resume_id: id, jobdesc: job };
      setLoading(true);
      let res = await getScore(data);

      const { task } = res.data;
      // @TODO: refactor this to only take task as input
      res = await fetchTask(task, accessToken);

      if (res.status === 'done') {
        const { score, insights } = res.payload;
        dispatch(projectSlice.actions.setScore(score));
        const newInsights = insights?.map((insight) => ({
          insight: insight,
          selected: false,
        }));

        dispatch(projectSlice.actions.setInsights(newInsights));
      } else {
        setTaskFailed(true);
      }
    } catch (e) {
      setTaskFailed(true);
      console.error(e);
    } finally {
      setLoading(false);
      dispatch(projectSlice.actions.setPage(2));
      navigate('/project-view');
      dispatch(projectSlice.actions.setFromHomePage(false));
    }
  };

  const button = (
    <button
      onClick={
        fromHomePage ? createResumeFromHomePage : saveAndContinue
      }
      className="rounded w-full bg-[#2D9CDB] text-white flex justify-center items-center h-[44px] text-[14px]"
    >
      Proceed, my details are correct!
    </button>
  );

  return (
    <>
      {loading && <SpinnerLoader />}

      <NextStepWrapper className="flex flex-col justify-start lg:mt-28 space-y-6 p-8">
        <div className="flex flex-col justify-center items-center space-y-6">
          <StepTracker2 currentStep={1} />
          <span className="text-[#090909] text-center">
            Now, kindly review and confirm all the extracted info your
            resume is accurate
          </span>
        </div>
        <div className="flex lg:flex-col space-x-3 lg:space-x-0 lg:space-y-2">
          {button}
          {/* <button className="rounded border w-full border-[#BDBDBD] text-[#828282] flex justify-center items-center h-[44px] text-[14px] bg-transparent">
            Continue Editing
          </button> */}
        </div>
      </NextStepWrapper>
      {taskFailed && (
        <div className="text-red-500 ">
          Something went wrong. Try again later!
        </div>
      )}
    </>
  );
};

export default CreateOrUpdateResume;
