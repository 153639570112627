import React, { useCallback } from 'react';
import ToastEditor from './ToastEditor';
import TextInput from './TextInput';
import Location from './Location';

const PersonalInformation = ({ handleChange, data, setData }) => {
  const handleSummaryChange = useCallback(
    (content) => {
      if (data.summary !== content) {
        setData((prevData) => ({ ...prevData, summary: content }));
      }
    },
    [data.summary, setData]
  );

  return (
    <div className="flex flex-col space-y-2 ">
      <div className="text-[24px] md:text-[28px] ">
        Personal Information
      </div>
      <div className="flex justify-start flex-col items-center space-x-2 space-y-5 w-full">
        <div className="w-full flex flex-col md:flex-row space-x-2 justify-center items-center">
          <TextInput
            label="First Name"
            type="text"
            name="first-name"
            value={data['first-name']}
            handleChange={handleChange}
          />
          <TextInput
            label="Last Name"
            type="text"
            name="last-name"
            value={data['last-name']}
            handleChange={handleChange}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row space-x-2 justify-center ">
          <TextInput
            label="Email"
            type="email"
            name="email"
            value={data.email}
            handleChange={handleChange}
          />
          <TextInput
            label="Phone Number"
            type="tel"
            name="phone"
            value={data.phone}
            handleChange={handleChange}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row space-x-2 justify-center ">
          <Location data={data} setData={setData} />
        </div>
        <div className="w-full flex space-x-2 justify-center ">
          <TextInput
            label="Job Title"
            type="text"
            name="job-title"
            value={data['job-title']}
            handleChange={handleChange}
          />
        </div>
        <div className="w-full flex space-x-2 justify-center ">
          <div className="text-[14px] text-[#494B57] flex flex-col items-start justify-center space-y-2 w-full">
            <div className="">Professional Summary</div>
            <ToastEditor
              initialValue={data.summary || ''}
              onChange={handleSummaryChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
