import React from 'react';

const ConfirmDeleteDialogue = ({
  title = 'Remove itme?',
  description = 'Are you sure you want to delete this item? You will lose all data related to this item.',
  cancel,
  handleDelete,
}) => {
  return (
    <div className="fixed top-0 left-0 h-full w-full bg-[#4142609E] bg-opacity-62 flex justify-center items-center z-[1000000]">
      <div className="bg-white p-6 rounded-lg w-80vw md:w-[464px] space-y-8">
        <div className="text-xl font-semibold text-black text-left">
          <h3 className="text-brand-blue text-2xl">{title}</h3>
          <p
            className="text-base text-[#312E2E] font-normal mt-2"
            style={{ lineHeight: '28px' }}
          >
            {description}
          </p>
        </div>
        <div className="flex justify-end items-center space-x-4 mt-4">
          <button
            type="button"
            onClick={cancel}
            className="bg-white px-5 py-3 rounded-lg border border-[#BDBDBD] text-[#BDBDBD]"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="flex justify-center space-x-2 items-center text-white bg-[#FF6058] px-5 py-3 rounded-lg"
          >
            <div>
              <img
                src="./images/icons/trash-icon.png"
                alt="delete icon"
              />
            </div>
            <span>Delete</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteDialogue;
