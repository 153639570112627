import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { Auth0Provider } from '@auth0/auth0-react';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import history from './utils/history';
import { getConfig } from './config';
import ReactGA from 'react-ga4';
import Toast from './components/common/Toast';

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/user-profile`,
    ...(config.audience ? { audience: config.audience } : null),
  },
};

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

ReactGA.initialize('G-BC0HDV9TPJ');

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0Provider {...providerConfig}>
        <App />
        {/* <ToastContainer position="bottom-right" autoClose={3000} /> */}
      </Auth0Provider>
    </Provider>
  </React.StrictMode>
);
