import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  user: null,
  createResumePermission: true,
  hasActiveSubscription: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, { payload }) => {
      state.user = payload;
    },
    setAccessToken: (state, { payload }) => {
      state.accessToken = payload;
    },
    setCreateResumePermission: (state, { payload }) => {
      state.createResumePermission = payload;
    },
    setHasActiveSubscription: (state, { payload }) => {
      state.hasActiveSubscription = payload;
    },
  },
});

export default authSlice;
