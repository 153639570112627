import React, { useState } from 'react';
import Portal from '.././Portal';
import { useNavigate } from 'react-router-dom';
import { ResumeTemplates } from '../Cards';
import ModalWrapper from '../StyledComponents/ModalWrapper.styled';
import { useScreenWidth } from '../../../hooks';
import { projectSlice } from '../../../store';
import { useSelector, useDispatch } from 'react-redux';
import { DownloadSuccessfulCard } from '../Cards';
import { useUpdateResumeMutation } from '../../../store';
import { SpinnerLoader } from '../Loader';
import FeedbackForm from '../Cards/FeedbackForm';
import { modalSlice } from '../../../store';
import { ErrorToast } from '../Toast';

const ExportCvBtn = ({ data }) => {
  const [error, setError] = useState({ message: '' });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { downloadSuccessful } = useSelector((state) => state.modal);
  const { resumes } = useSelector((state) => state.project);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const screenWidth = useScreenWidth();

  const { resumeId, enhancedResume } = useSelector(
    (state) => state.project
  );
  const [
    updateResume,
    { data: updatedResume, isLoading, error: updateResumeError },
  ] = useUpdateResumeMutation();

  const resumeCount = resumes.length;

  if (updateResumeError) {
    setError({ message: 'Failed to update resume' });
    console.log(error);
  }

  if (isLoading) {
    return <SpinnerLoader text="Updating your resume..." />;
  }

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const exportCV = async () => {
    dispatch(projectSlice.actions.setEnhancedResume(data));
    try {
      const jsonData = JSON.stringify(enhancedResume);

      const updateResume1 = await updateResume({
        id: resumeId,
        resume: { content: jsonData },
      });

      screenWidth > 1280
        ? setModalIsOpen(true)
        : navigate('/export-resume');
    } catch (error) {
      setError({ message: 'Failed to update resume' });
      console.error('Error updating resume:', error);
    }
  };

  return (
    <>
      {error.message && <ErrorToast message={error.message} />}
      <button
        onClick={exportCV}
        className="flex items-center justify-center px-8 py-3 text-white bg-brand-green w-full rounded text-center"
      >
        <div className="flex justify-between items-center space-x-4">
          <div>
            <img
              src="/images/icons/export.svg"
              alt="export"
              className="w-[24px] h-[24px]"
            />
          </div>
          <span className="text-[14px]">Export CV</span>
        </div>
        <div></div>
      </button>
      {modalIsOpen && screenWidth > 1280 && (
        <Portal>
          <ModalWrapper>
            <ResumeTemplates
              closeModal={closeModal}
              modalIsOpen={modalIsOpen}
            />
          </ModalWrapper>
        </Portal>
      )}
      {downloadSuccessful && (
        <Portal>
          <ModalWrapper>
            {/* @Todo: Use Resume lengnt to show feedback form */}
            {resumeCount < 2 ? (
              <FeedbackForm
                closeModal={() =>
                  dispatch(
                    modalSlice.actions.setDownloadSuccessful(false)
                  )
                }
              />
            ) : (
              <DownloadSuccessfulCard />
            )}
          </ModalWrapper>
        </Portal>
      )}
    </>
  );
};

export default ExportCvBtn;
