import { CompareResume } from '../../components/Dashboard';
import { useSelector } from 'react-redux';

const CompareResumePage = () => {
  const { activeResume } = useSelector((state) => state.project);
  const resume = activeResume;

  if (typeof resume.content === 'string') {
    resume.content = JSON.parse(resume.content);
  }

  return <CompareResume resume={resume} />;
};

export default CompareResumePage;
