import React, { useEffect, useState } from 'react';
import { authSlice, projectSlice } from '../../store';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardLayoutCols2 } from '../Layouts';
import { JobDescriptionFormLarge } from '../common/Forms';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { StepTracker2 } from '../common/StepTracker';
import { useGetScoreMutation } from '../../store';
import { SpinnerLoader } from '../common/Loader';
import { fetchTask } from '../../utils/fetchTask';
import Portal from '../common/Portal';
import { ModalWrapperStyle } from '../common/StyledComponents';
import { TrailEndedCard } from '../common/Cards';
import { UpgradePlanBtnWithImage } from '../common/Buttons';
import FeaturedResumes from '../common/FeaturedResumes';
import { ErrorToast } from '../common/Toast';

const Dashboard = () => {
  const hasActiveSubscription = useSelector(
    (state) => state.auth.hasActiveSubscription
  );
  const dispatch = useDispatch();
  //Reset continue without fixing state
  useEffect(() => {
    dispatch(projectSlice.actions.setContinueWithoutFixing(false));
  }, []);
  return (
    <DashboardLayoutCols2
      Main={<Main hasActiveSubscription={hasActiveSubscription} />}
      Side={<Side />}
    />
  );
};

const Main = ({ hasActiveSubscription }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className="w-full space-y-4 md:space-y-8 z-0">
      <div className="profile-header-shadow">
        <div className="flex items-end justify-between space-x-4 py-5">
          <div className="flex flex-col space-y-2 items-start justify-between profile-header-shadow lg:ml-10 mt-10">
            <div className="text-[32px] md:text-[40px] text-black dark:text-white min-w-fit joker2 mt-5">
              Hello{' '}
              <span className="capitalize">
                {user?.given_name} 👋{' '}
              </span>
            </div>
            <div className="font-normal text-[16px] md:text-xl text-[#737373]">
              Let's help you get that dream job!
            </div>
          </div>
          <div>
            {!hasActiveSubscription && <UpgradePlanBtnWithImage />}
          </div>
        </div>
      </div>
      <div
        className=" md:grid md:grid-cols-9  gap-6 space-y-4 md:space-y-0 justify-between items-start"
        style={{ gridTemplateColumns: '3fr 6fr' }}
      >
        <FeaturedResumes />
        <JobDescriptionFormLarge />
      </div>
    </div>
  );
};

const Side = () => {
  const [error, setError] = useState({ message: '' });
  const { job, activeResume, resumeId } = useSelector(
    (state) => state.project
  );
  const { createResumePermission } = useSelector(
    (state) => state.auth
  );
  const currentStep = 1;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getScore, { isLoading }] = useGetScoreMutation();
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = React.useState(false);
  const [taskFailed, setTaskFailed] = React.useState(false);
  const [upgradeModalIsOpen, setUpgradeModalIsOpen] =
    React.useState(false);

  useEffect(() => {
    if (activeResume) {
      dispatch(projectSlice.actions.setResumeId(activeResume.id));
      dispatch(
        projectSlice.actions.setResumeObj(activeResume.content)
      );
    }
  }, [activeResume, dispatch]);

  const handleGetScore = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://www.revats.ai/`,
        scope: 'read:current_user',
      });

      if (!createResumePermission) {
        setUpgradeModalIsOpen(true);
        return;
      }
      if (
        job === null ||
        job === '' ||
        job === undefined ||
        job.length === 0 ||
        job === '<p><br></p>' ||
        job === '<p></p>'
      ) {
        setError({ message: 'Please enter a job description' });
        return;
      }

      if (!activeResume) {
        setError({ message: 'Please select a resume' });
        return;
      }

      setLoading(true);

      dispatch(authSlice.actions.setAccessToken(accessToken));

      const data = { resume_id: resumeId, jobdesc: job };
      let res = await getScore(data);
      const { task } = res.data;
      res = await fetchTask(task, accessToken);

      if (res.status === 'done') {
        const { score, insights } = res.payload;
        dispatch(projectSlice.actions.setScore(score));

        const newInsights = insights?.map((insight) => ({
          insight: insight,
          selected: false,
        }));

        dispatch(projectSlice.actions.setInsights(newInsights));

        dispatch(projectSlice.actions.setPage(2));

        navigate('/project-view');
      } else {
        setTaskFailed(true);
      }
      setLoading(false);
    } catch (e) {
      setError({ message: 'Failed to get score' });
      console.log(e);
    }
  };

  return (
    <>
      {(isLoading || loading) && <SpinnerLoader />}
      {error.message && <ErrorToast message={error.message} />}
      <RightComponentWrapper className="relative pt-12 md:pt-0 mt-[-10px] h-full">
        <div className="flex flex-col justify-center space-y-6 h-full">
          <div className="flex flex-col justify-center  w-full md:justify-center items-center space-y-6 ">
            <StepTracker2 currentStep={currentStep} />
            <div className="text-[#090909] text-center flex justify-center items-center">
              Have you selected your CV and added a job description?
            </div>
          </div>
          <div className="flex flex-col space-y-2">
            <button
              disabled={
                job === null ||
                job === '' ||
                job === undefined ||
                job.length === 0 ||
                job === '<p><br></p>' ||
                job === '<p></p>' ||
                !activeResume
              }
              onClick={handleGetScore}
              className={`rounded bg-brand-cyan-dark w-full border border-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] text-white ${
                job === null ||
                job === '' ||
                job === undefined ||
                job.length === 0 ||
                job === '<p><br></p>' ||
                job === '<p></p>' ||
                !activeResume
                  ? 'opacity-50 cursor-not-allowed'
                  : 'cursor-pointer'
              }`}
            >
              Yes, Compare Now
            </button>
          </div>
        </div>
      </RightComponentWrapper>
      {upgradeModalIsOpen && (
        <Portal>
          <ModalWrapperStyle className="w-full h-full">
            <TrailEndedCard
              setUpgradeModalIsOpen={setUpgradeModalIsOpen}
            />
          </ModalWrapperStyle>
        </Portal>
      )}
    </>
  );
};

const RightComponentWrapper = styled.div`
  background: #fff;
  box-shadow: 0px -5px 4px 0px rgba(0, 0, 0, 0.04);

  @media (min-width: 768px) {
    background-color: transparent;
    box-shadow: none;
  }
`;

export default Dashboard;
