import React, { useCallback, useState } from 'react';
import TextInput from './TextInput';
import { XCircleIcon } from '@heroicons/react/20/solid';
import styled from 'styled-components';
import NewLink from './NewLink';
import { ConfirmDeleteDialogue } from '../../Dialogue';

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000;
  &:hover {
    color: #d30000;
  }
`;

const Links = React.memo(({ links, setData, handleAddLink }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false); // State for delete dialogue
  const [selectedLinkIndex, setSelectedLinkIndex] = useState(null); // State to track which link to delete

  const handleDeleteLink = useCallback((index) => {
    setSelectedLinkIndex(index); // Set the index of the link to delete
    setShowDeleteDialog(true); // Open the delete dialogue
  }, []);

  const confirmDelete = useCallback(() => {
    setData((prevData) => {
      const updatedLinks = prevData.links.filter(
        (_, i) => i !== selectedLinkIndex
      );
      return { ...prevData, links: updatedLinks };
    });
    setShowDeleteDialog(false); // Close the dialogue after deletion
    setSelectedLinkIndex(null); // Reset the index
  }, [selectedLinkIndex, setData]);

  const handleLinkChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      setData((prevData) => {
        const updatedLinks = [...(prevData.links || [])];
        updatedLinks[index] = {
          ...updatedLinks[index],
          [name]: value,
        };
        return { ...prevData, links: updatedLinks };
      });
    },
    [setData]
  );

  return (
    <div className="flex flex-col space-y-2">
      <span className="text-[24px] md:text-[28px]">Links</span>
      <div className="flex flex-wrap space-x-2">
        {links.map((link, index) => (
          <div className="w-full md:max-w-[48%]" key={index}>
            <div className="flex justify-between items-center w-full mb-5">
              <TextInput
                label="Title"
                type="text"
                name="title"
                value={link.title}
                handleChange={(e) => handleLinkChange(e, index)}
              />
              <DeleteButton
                type="button"
                onClick={() => handleDeleteLink(index)}
              >
                <XCircleIcon className="h-5 w-5" />
              </DeleteButton>
            </div>
            <TextInput
              label="URL"
              type="text"
              name="value"
              value={link.value}
              handleChange={(e) => handleLinkChange(e, index)}
            />
          </div>
        ))}
      </div>
      <NewLink handleAddLink={handleAddLink} />
      {showDeleteDialog && (
        <ConfirmDeleteDialogue
          title="Remove link?"
          description="Are you sure you want to delete this link? You will lose all data related to this link."
          cancel={() => setShowDeleteDialog(false)}
          handleDelete={confirmDelete}
        />
      )}
    </div>
  );
});

export default Links;
