import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { ExportCvBtn, UndoChangesBtn } from '../../Buttons';
import { numToRomanNumeralsPair } from '../../../../constants';
import PersonalInformation from './PersonalInformation';
import Skills from './Skills';
import Education from './Education';
import WorkExperience from './WorkExperience';
import Certificates from './Certificates';
import Links from './Links';
import NewLink from './NewLink';

const ResumeForm = ({ selectedTab, data, setData }) => {
  // Initialize refs only once
  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();

  // Memoize the refs object to prevent re-creation on each render
  const refs = useMemo(
    () => ({
      section1: section1Ref,
      section2: section2Ref,
      section3: section3Ref,
      section4: section4Ref,
      section5: section5Ref,
    }),
    []
  );

  const { page } = useSelector((state) => state.project);

  // Ref to track if the initial scroll has been performed
  const hasScrolled = useRef(false);

  // Scroll to selected tab with initial scroll handling
  useLayoutEffect(() => {
    if (!hasScrolled.current) {
      // Perform initial scroll to section1
      const initialRef = refs.section1;
      if (initialRef?.current) {
        initialRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
        hasScrolled.current = true; // Mark that initial scroll has been done
      }
    } else {
      // Scroll to the selected tab on subsequent changes
      const ref = refs[selectedTab];
      console.log('ref', ref);
      console.log('refs', refs);
      console.log('selectedTab:', selectedTab);

      if (ref?.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [selectedTab, refs]);

  // Split full name into first and last name if data.name changes
  useEffect(() => {
    if (!data?.name) return;

    function splitFullName(fullName) {
      const names = fullName.trim().split(/\s+/);
      const lastName = names.length > 1 ? names.pop() : '';
      const firstName = names.join(' ');
      return { firstName, lastName };
    }

    const { firstName, lastName } = splitFullName(data.name);

    // Avoid setting data if the name hasn't changed
    if (
      data['first-name'] !== firstName ||
      data['last-name'] !== lastName
    ) {
      setData((prevData) => ({
        ...prevData,
        'first-name': firstName,
        'last-name': lastName,
      }));
    }
  }, [data?.name, setData]);

  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      setData((prevData) => ({ ...prevData, [name]: value }));
    },
    [setData]
  );

  const handleDateRangeChange = useCallback(
    (e, index, section) => {
      const { name, value } = e.target;
      setData((prevData) => {
        const updatedSection = [...(prevData[section] || [])];
        updatedSection[index] = {
          ...updatedSection[index],
          [name]: value,
        };
        return { ...prevData, [section]: updatedSection };
      });
    },
    [setData]
  );

  const handleAddLink = useCallback(
    (e, newLink) => {
      e.preventDefault();
      setData((prevData) => ({
        ...prevData,
        links: [...(prevData.links || []), newLink],
      }));
    },
    [setData]
  );

  const Congratulations = (
    <div className="flex flex-col justify-center items-center w-full ">
      <div className="mb-2 flex justify-center">
        <img
          src="/images/gifs/success.gif"
          alt="success"
          className="w-[100%] h-[100%]"
        />
      </div>
      <div className="flex flex-col justify-center items-center text-center text-black font-normals mb-10">
        <span className="text-brand-green text-xl lg:text-3xl mb-2 joker2">
          Awesome!
        </span>
        <span className="mb-2">
          Your CV has been updated to match the job description!
        </span>
        <span>
          Now, you can export your CV using any of our beautiful
          templates.
          <br />
          We are rooting for you!{' '}
          <span className="font-bold">Good luck!</span>
        </span>
      </div>
      <div className="flex justify-center space-x-6 w-fit ">
        <div>
          <ExportCvBtn data={data} />
        </div>
        <div>
          <UndoChangesBtn />
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full h-full">
      <div className="mb-8 mx-3 mt-4 lg:mt-0 md:mx-0">
        {page === 2 && (
          <div className="flex items-center space-x-3">
            {/* Include any components or content for page 2 */}
          </div>
        )}
        {page === 3 && Congratulations}
      </div>
      <form className="bg-white lg:bg-[#D9D9D9] lg:bg-opacity-10 px-3 md:px-10 py-6 flex flex-col w-full h-full space-y-[58px] ">
        <div ref={section1Ref}>
          <PersonalInformation
            handleChange={handleChange}
            data={data}
            setData={setData}
          />
        </div>
        <div ref={section2Ref}>
          <Skills setData={setData} data={data} />
        </div>
        <div ref={section3Ref} className="w-full">
          <Education
            setData={setData}
            handleDateRangeChange={handleDateRangeChange}
            data={data}
          />
        </div>
        <div ref={section4Ref} className="w-full">
          <WorkExperience
            data={data}
            setData={setData}
            handleDateRangeChange={handleDateRangeChange}
          />
        </div>
        <div className="w-full">
          <Certificates data={data} setData={setData} />
        </div>
        <div ref={section5Ref} />
        {data.links ? (
          <Links
            handleChange={handleChange}
            links={data.links}
            handleAddLink={handleAddLink}
            setData={setData}
          />
        ) : (
          <NewLink handleAddLink={handleAddLink} />
        )}
      </form>
    </div>
  );
};

export default ResumeForm;
