import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import WorkExperienceItem from './WorkExperienceItem';
import { Plus } from 'react-feather';
import { ConfirmDeleteDialogue } from '../../Dialogue';
import { toast } from 'react-toastify';

const WorkExperience = React.memo(
  ({ data, setData, handleDateRangeChange }) => {
    const workExperiences = data.experiences || [];
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedExperienceIndex, setSelectedExperienceIndex] =
      useState(null);

    const handleWorkExperienceChange = useCallback(
      (index, field, value) => {
        setData((prevData) => {
          const updatedExperiences = [
            ...(prevData.experiences || []),
          ];
          updatedExperiences[index] = {
            ...updatedExperiences[index],
            [field]: value,
          };
          return { ...prevData, experiences: updatedExperiences };
        });
      },
      [setData]
    );

    const handleAddWorkExperience = useCallback(() => {
      const newExperience = {
        id: uuidv4(),
        company: '',
        details: '',
        'start-date': '',
        'end-date': '',
        keywords: [],
        summary: '',
        title: '',
      };
      setData((prevData) => ({
        ...prevData,
        experiences: [...(prevData.experiences || []), newExperience],
      }));
    }, [setData]);

    const handleDeleteExperience = useCallback((index) => {
      setSelectedExperienceIndex(index);
      setShowDeleteDialog(true);
    }, []);

    const confirmDeleteExperience = () => {
      handleDeleteExperience(selectedExperienceIndex);
      setShowDeleteDialog(false);
      toast.success('Work experience deleted successfully');
    };

    return (
      <>
        {workExperiences.length > 0 ? (
          <div className="flex flex-col space-y-2">
            {workExperiences.map((work, index) => (
              <WorkExperienceItem
                key={work.id || index}
                work={work}
                index={index}
                handleWorkExperienceChange={
                  handleWorkExperienceChange
                }
                handleDateRangeChange={handleDateRangeChange}
                handleDeleteExperience={handleDeleteExperience}
              />
            ))}
            <button
              type="button"
              onClick={handleAddWorkExperience}
              className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px]"
            >
              <Plus className="mr-1 h-[12px] w-[12px]" />
              Add New Experience
            </button>
          </div>
        ) : (
          <button
            type="button"
            onClick={handleAddWorkExperience}
            className="w-full rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px]"
          >
            <Plus className="mr-1 h-[12px] w-[12px]" />
            Add Work Experience
          </button>
        )}

        {showDeleteDialog && (
          <ConfirmDeleteDialogue
            title="Remove work experience?"
            description="Are you sure you want to delete this work experience? You will lose all data related to this experience."
            cancel={() => setShowDeleteDialog(false)}
            handleDelete={confirmDeleteExperience}
          />
        )}
      </>
    );
  }
);

export default WorkExperience;
