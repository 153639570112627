import React, { useState, useEffect, useRef } from 'react';
import { MoreVertical } from 'react-feather';
import styled from 'styled-components';
import {
  useDeleteResumeMutation,
  useSetDefaultResumeMutation,
  useResumesMutation,
} from '../../../store';
import { projectSlice } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ActiveResumeTag from './ActiveResumeTag';
import { SpinnerLoader } from '../Loader';
import { FaRegCircle } from 'react-icons/fa';
import { ConfirmDeleteDialogue } from '../Dialogue';

const ResumeCardSmall = ({ title, lastUpdated, content, id }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const { activeResume, resumes, defaultResume } = useSelector(
    (state) => state.project
  );

  const [resume] = useState(content);
  const menuRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deleteResume, { isLoading, data }] =
    useDeleteResumeMutation();
  const [setDefaultResume, { isLoading: setDefaultResumeIsLoading }] =
    useSetDefaultResumeMutation();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [showMenu]);

  useEffect(() => {}, [activeResume]);

  const editResume = () => {
    dispatch(
      projectSlice.actions.setResumeObj({
        ...resume,
        id,
        title,
      })
    );
    dispatch(projectSlice.actions.setPage(1));
    navigate('/project-view');
  };

  const handleDeleteResume = async () => {
    const resumeId = id;
    if (activeResume?.id === resumeId) {
      dispatch(
        projectSlice.actions.setActiveResume(resumes[0] || null)
      );
    }
    if (defaultResume?.id === resumeId) {
      dispatch(
        projectSlice.actions.setDefaultResume(resumes[0] || null)
      );
    }
    dispatch(projectSlice.actions.removeResume(resumeId));

    const res = await deleteResume(resumeId);

    setShowDeleteDialog(false);
  };

  const handleSetActiveResume = () => {
    dispatch(
      projectSlice.actions.setActiveResume({
        content,
        id,
        title,
      })
    );
  };

  return (
    <button onClick={handleSetActiveResume} className="w-full">
      {(isLoading || setDefaultResumeIsLoading) && <SpinnerLoader />}
      <div className="relative flex justify-center w-full items-center border border-solid border-[#B3D7E6] rounded-2xl p-4 min-h-[36px] shadow-sm hover:shadow-xl transition-shadow duration-300 ring-blue-500">
        <div className="flex items-center justify-start w-full ">
          <div className="min-w-[24px] min-h-[24px] mr-6">
            {activeResume?.id === id ? (
              <img
                src="/images/icons/check-filled.png"
                alt="application"
                className="w-full h-full"
              />
            ) : (
              <FaRegCircle
                className="w-[20px] h-[20px]"
                color="#2D9CDB"
              />
            )}
          </div>
          <div className="flex justify-center items-center space-x-5">
            <div className="w-16 h-12 min-w-[64px] min-h-[48px] flex justify-center items-end bg-[#CEE8F3] rounded-lg">
              <img
                src="/images/dashboard/application.png"
                alt="application"
                className="w-12 h-9"
              />
            </div>
            <div className="w-[120px] md:w-[194px] text-left">
              {activeResume?.id === id && <ActiveResumeTag />}
              <div className="text-brand-cyan-dark opacity-[0.8] font-semibold text-base text-ellipsis overflow-hidden whitespace-nowrap">
                {title}
              </div>
              <div className="text-black font-normal text-sm opacity-[0.7]">
                <span className="text-[#828282]">Last edited </span>{' '}
                {lastUpdated}
              </div>
            </div>
          </div>

          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowMenu(!showMenu);
            }}
            className="absolute text-[#DAE2E5] flex items-center right-0 top-0 bottom-0"
          >
            <MoreVertical
              className="w-6 h-6 ml-3 mr-2"
              color="#828282"
            />
          </button>

          {showMenu && (
            <MoreMenu
              ref={menuRef}
              className="absolute top-14 right-0 mr-[-40px] md:mr-[-120px] flex flex-col space-y-6 p-4 w-[220px] text-left items-start"
            >
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  editResume();
                  setShowMenu(false);
                }}
                className="flex space-x-3 items-center justify-center text-xs"
              >
                <div>
                  <img
                    src="./images/icons/edit-icon.png"
                    alt="edit icon"
                  />
                </div>
                <span>Edit Resume</span>
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteDialog(true);
                  setShowMenu(false);
                }}
                className="flex space-x-3 items-center justify-center text-xs text-[#E95050]"
              >
                <div>
                  <img
                    src="./images/icons/delete-icon.png"
                    alt="delete icon"
                  />
                </div>
                <span>Delete</span>
              </button>
            </MoreMenu>
          )}
        </div>
      </div>

      {showDeleteDialog && (
        <ConfirmDeleteDialogue
          title="Remove resume?"
          description="Are you sure you want to delete this resume? You will lose all data related to this resume."
          cancel={() => setShowDeleteDialog(false)}
          handleDelete={handleDeleteResume}
        />
      )}
    </button>
  );
};

const MoreMenu = styled.div`
  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 11px 28.4px 2px rgba(0, 0, 0, 0.12);
  z-index: 1000;
`;

export default ResumeCardSmall;
