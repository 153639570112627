import React, { useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextInput from './TextInput';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { Plus } from 'react-feather';
import styled from 'styled-components';
import { ConfirmDeleteDialogue } from '../../Dialogue';

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000;
  &:hover {
    color: #d30000;
  }
`;

const Skills = React.memo(({ data, setData }) => {
  const [newKeywords, setNewKeywords] = useState({});
  const [showDeleteSkillDialog, setShowDeleteSkillDialog] =
    useState(false);
  const [showDeleteKeywordDialog, setShowDeleteKeywordDialog] =
    useState(false);
  const [selectedSkillIndex, setSelectedSkillIndex] = useState(null);
  const [selectedSkillId, setSelectedSkillId] = useState(null);
  const [selectedKeywordIndex, setSelectedKeywordIndex] =
    useState(null);

  // Ensure each skill has a unique id
  useEffect(() => {
    if (data.skills) {
      const skillsNeedId = data.skills.some((skill) => !skill.id);
      if (skillsNeedId) {
        const updatedSkills = data.skills.map((skill) => {
          if (!skill.id) {
            return { ...skill, id: uuidv4() };
          }
          return skill;
        });
        setData((prevData) => ({
          ...prevData,
          skills: updatedSkills,
        }));
      }
    }
  }, [data.skills, setData]);

  const skills = data.skills || [];

  const handleSkillNameChange = useCallback(
    (e, index) => {
      const { value } = e.target;
      setData((prevData) => {
        const updatedSkills = [...(prevData.skills || [])];
        updatedSkills[index] = {
          ...updatedSkills[index],
          name: value,
        };
        return { ...prevData, skills: updatedSkills };
      });
    },
    [setData]
  );

  const handleKeywordChange = useCallback(
    (e, skillId, keywordIndex) => {
      const { value } = e.target;
      setData((prevData) => {
        const updatedSkills = [...(prevData.skills || [])];
        const skillIndex = updatedSkills.findIndex(
          (skill) => skill.id === skillId
        );
        if (skillIndex !== -1) {
          const updatedKeywords = [
            ...(updatedSkills[skillIndex].keywords || []),
          ];
          updatedKeywords[keywordIndex] = value;
          updatedSkills[skillIndex] = {
            ...updatedSkills[skillIndex],
            keywords: updatedKeywords,
          };
          return { ...prevData, skills: updatedSkills };
        }
        return prevData;
      });
    },
    [setData]
  );

  const handleDeleteSkill = useCallback((index) => {
    setSelectedSkillIndex(index);
    setShowDeleteSkillDialog(true);
  }, []);

  const confirmDeleteSkill = useCallback(() => {
    setData((prevData) => {
      const updatedSkills = prevData.skills.filter(
        (_, i) => i !== selectedSkillIndex
      );
      return { ...prevData, skills: updatedSkills };
    });
    setShowDeleteSkillDialog(false);
    setSelectedSkillIndex(null);
  }, [selectedSkillIndex, setData]);

  const handleDeleteKeyword = useCallback((skillId, keywordIndex) => {
    setSelectedSkillId(skillId);
    setSelectedKeywordIndex(keywordIndex);
    setShowDeleteKeywordDialog(true);
  }, []);

  const confirmDeleteKeyword = useCallback(() => {
    setData((prevData) => {
      const updatedSkills = [...(prevData.skills || [])];
      const skillIndex = updatedSkills.findIndex(
        (skill) => skill.id === selectedSkillId
      );
      if (skillIndex !== -1) {
        const updatedKeywords = updatedSkills[
          skillIndex
        ].keywords.filter((_, i) => i !== selectedKeywordIndex);
        updatedSkills[skillIndex] = {
          ...updatedSkills[skillIndex],
          keywords: updatedKeywords,
        };
        return { ...prevData, skills: updatedSkills };
      }
      return prevData;
    });
    setShowDeleteKeywordDialog(false);
    setSelectedSkillId(null);
    setSelectedKeywordIndex(null);
  }, [selectedSkillId, selectedKeywordIndex, setData]);

  const handleAddSkill = useCallback(() => {
    const newSkill = {
      id: uuidv4(),
      name: '',
      level: '',
      keywords: [],
    };
    setData((prevData) => ({
      ...prevData,
      skills: [...(prevData.skills || []), newSkill],
    }));
  }, [setData]);

  const handleAddKeyword = useCallback(
    (skillId) => {
      const newKeyword = newKeywords[skillId]?.trim();
      if (newKeyword) {
        setData((prevData) => {
          const updatedSkills = [...(prevData.skills || [])];
          const skillIndex = updatedSkills.findIndex(
            (skill) => skill.id === skillId
          );
          if (skillIndex !== -1) {
            const updatedKeywords = [
              ...(updatedSkills[skillIndex].keywords || []),
              newKeyword,
            ];
            updatedSkills[skillIndex] = {
              ...updatedSkills[skillIndex],
              keywords: updatedKeywords,
            };
            return { ...prevData, skills: updatedSkills };
          }
          return prevData;
        });
        setNewKeywords((prev) => ({ ...prev, [skillId]: '' }));
      }
    },
    [newKeywords, setData]
  );

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-[24px] md:text-[28px]">Skills</div>
      <div className="flex flex-col space-y-4 w-full">
        {skills.map((skill, index) => (
          <div className="w-full" key={skill.id || index}>
            <div className="flex justify-between items-center w-full mb-2">
              <TextInput
                label=""
                type="text"
                name="name"
                value={skill.name}
                handleChange={(e) => handleSkillNameChange(e, index)}
                className="w-full font-bold text-[18px]"
                placeholder="Skill Name"
              />
              <DeleteButton
                type="button"
                onClick={() => handleDeleteSkill(index)}
              >
                <XCircleIcon className="h-5 w-5" />
              </DeleteButton>
            </div>
            <div className="ml-4 md:ml-6">
              {skill.keywords &&
                skill.keywords.map((keyword, keywordIndex) => (
                  <div
                    key={`${skill.id}-${keywordIndex}`}
                    className="flex items-center space-x-2 mb-2"
                  >
                    <TextInput
                      label=""
                      type="text"
                      name="keyword"
                      value={keyword}
                      handleChange={(e) =>
                        handleKeywordChange(e, skill.id, keywordIndex)
                      }
                      className="text-[14px] w-full"
                      placeholder="Keyword"
                    />
                    <DeleteButton
                      type="button"
                      onClick={() =>
                        handleDeleteKeyword(skill.id, keywordIndex)
                      }
                    >
                      <XCircleIcon className="h-5 w-5" />
                    </DeleteButton>
                  </div>
                ))}
              <div className="flex items-center space-x-2 mt-2">
                <TextInput
                  label=""
                  type="text"
                  name="newKeyword"
                  value={newKeywords[skill.id] || ''}
                  handleChange={(e) =>
                    setNewKeywords((prev) => ({
                      ...prev,
                      [skill.id]: e.target.value,
                    }))
                  }
                  className="text-[14px] w-full"
                  placeholder="Add new keyword"
                />
                <button
                  type="button"
                  onClick={() => handleAddKeyword(skill.id)}
                  className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center text-[12px] px-2 py-2 "
                >
                  Add Keyword
                </button>
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddSkill}
          className="w-full rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add Skill
        </button>
      </div>
      {showDeleteSkillDialog && (
        <ConfirmDeleteDialogue
          title="Remove skill?"
          description="Are you sure you want to delete this skill? You will lose all data related to this skill."
          cancel={() => setShowDeleteSkillDialog(false)}
          handleDelete={confirmDeleteSkill}
        />
      )}
      {showDeleteKeywordDialog && (
        <ConfirmDeleteDialogue
          title="Remove keyword?"
          description="Are you sure you want to delete this keyword?"
          cancel={() => setShowDeleteKeywordDialog(false)}
          handleDelete={confirmDeleteKeyword}
        />
      )}
    </div>
  );
});

export default Skills;
