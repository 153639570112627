import React, { useCallback, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import TextInput from './TextInput';
import DateInput from './DateInput';
import { Plus } from 'react-feather';
import { XCircleIcon } from '@heroicons/react/20/solid';
import styled from 'styled-components';
import { ConfirmDeleteDialogue } from '../../Dialogue';

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000;
  &:hover {
    color: #d30000;
  }
`;

const Certificates = React.memo(({ data, setData }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedCertificateIndex, setSelectedCertificateIndex] =
    useState(null);

  const certifications = data.certifications || [];

  const handleCertificateChange = useCallback(
    (e, index) => {
      const { name, value } = e.target;
      setData((prevData) => {
        const updatedCertifications = [
          ...(prevData.certifications || []),
        ];
        updatedCertifications[index] = {
          ...updatedCertifications[index],
          [name]: value,
        };

        return { ...prevData, certifications: updatedCertifications };
      });
    },
    [setData]
  );

  const handleAddCertificate = useCallback(() => {
    const newCertificate = {
      id: uuidv4(),
      name: '',
      date: '',
      issuer: '',
      summary: '',
      url: '',
    };
    setData((prevData) => ({
      ...prevData,
      certifications: [
        ...(prevData.certifications || []),
        newCertificate,
      ],
    }));
  }, [setData]);

  const handleDeleteCertificate = useCallback(
    (index) => {
      setData((prevData) => {
        const updatedCertifications = prevData.certifications.filter(
          (_, i) => i !== index
        );
        return { ...prevData, certifications: updatedCertifications };
      });
      setShowDeleteDialog(false);
    },

    [setData]
  );

  return (
    <>
      <div className="flex flex-col space-y-2">
        <div className="text-[24px] md:text-[28px]">
          Certifications and Awards
        </div>
        {certifications.map((certificate, index) => (
          <div
            key={certificate.id || index}
            className="flex flex-col space-y-4 mb-5"
          >
            <div className="flex justify-between items-center w-full mb-2">
              <span className="font-bold">
                {certificate.name || 'New Certificate'}
              </span>
              <DeleteButton
                type="button"
                onClick={() => {
                  setSelectedCertificateIndex(index);
                  setShowDeleteDialog(true);
                }}
              >
                <XCircleIcon className="h-5 w-5" />
              </DeleteButton>
            </div>
            <div className="w-full flex flex-col md:flex-row space-x-2">
              <TextInput
                label="Certificate Name"
                type="text"
                name="name"
                value={certificate.name}
                handleChange={(e) =>
                  handleCertificateChange(e, index)
                }
              />
              <TextInput
                label="Issuer"
                type="text"
                name="issuer"
                value={certificate.issuer}
                handleChange={(e) =>
                  handleCertificateChange(e, index)
                }
              />
            </div>
            <div className="w-full flex flex-col md:flex-row space-x-2">
              <TextInput
                label="URL"
                type="text"
                name="url"
                value={certificate.url}
                handleChange={(e) =>
                  handleCertificateChange(e, index)
                }
              />
              <DateInput
                label="Date Achieved"
                type="text"
                name="date"
                value={certificate.date}
                handleChange={(e) =>
                  handleCertificateChange(e, index)
                }
              />
            </div>
            <TextInput
              label="Summary"
              type="textArea"
              name="summary"
              value={certificate.summary}
              handleChange={(e) => handleCertificateChange(e, index)}
            />
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddCertificate}
          className="rounded border border-brand-cyan-dark text-brand-cyan-dark flex justify-center items-center h-[44px] text-[14px] "
        >
          <Plus className="mr-1 h-[12px] w-[12px]" />
          Add New Certificate
        </button>
      </div>
      {showDeleteDialog && (
        <ConfirmDeleteDialogue
          title="Remove certificate?"
          description="Are you sure you want to delete this certificate? You will lose all data related to this certificate."
          cancel={() => setShowDeleteDialog(false)}
          handleDelete={() =>
            handleDeleteCertificate(selectedCertificateIndex)
          }
        />
      )}
    </>
  );
});

export default Certificates;
