import React, { useState, useCallback } from 'react';
import TextInput from './TextInput';
import DateInput from './DateInput';
import ToastEditor from './ToastEditor';
import { XCircleIcon } from '@heroicons/react/20/solid';
import styled from 'styled-components';

const DeleteButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ff0000;
  &:hover {
    color: #d30000;
  }
`;

const WorkExperienceItem = React.memo(function WorkExperienceItem({
  work,
  index,
  handleWorkExperienceChange,
  handleDateRangeChange,
  handleDeleteExperience,
}) {
  const [editorContent, setEditorContent] = useState(
    work.details || ''
  );

  const handleEditorChange = useCallback(
    (content) => {
      setEditorContent(content);
      handleWorkExperienceChange(index, 'details', content);
    },
    [index, handleWorkExperienceChange]
  );

  return (
    <div key={work.id || index}>
      <div className="flex justify-between items-center w-full mb-2">
        <span className="text-[24px] md:text-[28px]">
          Work Experience {index + 1}
        </span>
        <DeleteButton
          type="button"
          onClick={() => handleDeleteExperience(index)}
        >
          <XCircleIcon className="h-5 w-5" />
        </DeleteButton>
      </div>
      <div className="flex flex-col space-y-5 w-full mb-5">
        <div className="w-full flex flex-col md:flex-row space-x-2">
          <TextInput
            label="Job Title"
            type="text"
            name="title"
            value={work.title}
            handleChange={(e) =>
              handleWorkExperienceChange(
                index,
                'title',
                e.target.value
              )
            }
          />
          <TextInput
            label="Name of Company"
            type="text"
            name="company"
            value={work.company}
            handleChange={(e) =>
              handleWorkExperienceChange(
                index,
                'company',
                e.target.value
              )
            }
          />
        </div>
        <div className="w-full">
          <div className="text-[14px] text-[#494B57] flex flex-col space-y-2">
            <div>Professional Summary</div>

            <ToastEditor
              initialValue={editorContent}
              onChange={handleEditorChange}
            />
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row space-x-2">
          <DateInput
            label="Start Year"
            type="text"
            name="start-date"
            value={work['start-date']}
            handleChange={(e) =>
              handleDateRangeChange(e, index, 'experiences')
            }
          />
          <DateInput
            label="End Year"
            type="text"
            name="end-date"
            value={work['end-date']}
            handleChange={(e) =>
              handleDateRangeChange(e, index, 'experiences')
            }
          />
        </div>
      </div>
    </div>
  );
});

export default WorkExperienceItem;
