import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useSetDefaultResumeMutation,
  useGetUserDetailsMutation,
  useUploadPdfMutation,
} from '../store';
import { projectSlice, authSlice } from '../store';
import { Dashboard as UserDashboard } from '../components/Dashboard';
import base64ToBlob from '../utils/base64ToBlob';
import { SpinnerLoader } from '../components/common/Loader';
import ContactUs from '../components/common/ContactUs';
import DashboardHeader from '../components/common/DashboardHeader';
import { fetchTask } from '../utils/fetchTask';
import { ErrorToast } from '../components/common/Toast';

const REPRESENTS_UNSET_VALUES = -1;

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '' });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const { resumes } = useSelector((state) => state.project);

  const [getUserDetails, { data, isLoading }] =
    useGetUserDetailsMutation();
  const [uploadPdf, { isLoading: uploadPdfIsLoading }] =
    useUploadPdfMutation();
  const [setDefaultResume, { isLoading: setDefaultResumeIsLoading }] =
    useSetDefaultResumeMutation();

  const getUser = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      dispatch(authSlice.actions.setAccessToken(token));
      await getUserDetails().unwrap();
    } catch (error) {
      setError({ message: 'Failed to fetch user' });
      console.error('Error fetching user:', error);
    }
  }, [dispatch, getAccessTokenSilently, getUserDetails]);

  const checkAndNavigateToProjectView = useCallback(() => {
    const resume = localStorage.getItem('resume');
    const job = localStorage.getItem('job');
    const resumeTitle = localStorage.getItem('resumeTitle');

    if (!resume || !job || !resumeTitle) return;

    setLoading(true);
    dispatch(projectSlice.actions.setFromHomePage(true));
    const file = base64ToBlob(resume);

    const pdf2Object = async () => {
      try {
        const accessToken = await getAccessTokenSilently();
        dispatch(authSlice.actions.setAccessToken(accessToken));

        let res = await uploadPdf(file).unwrap();
        res = await fetchTask(res.data.task, accessToken);

        if (res.status === 'done') {
          dispatch(projectSlice.actions.setResumeObj(res.payload));
          dispatch(projectSlice.actions.setJob(job));
          dispatch(projectSlice.actions.setResumeTitle(resumeTitle));
        }
      } catch (error) {
        console.error(error.message);
        setError({ message: 'Failed to upload resume' });
      } finally {
        setLoading(false);
        localStorage.removeItem('resume');
        localStorage.removeItem('job');
        localStorage.removeItem('resumeTitle');
        navigate('/project-view');
      }
    };

    pdf2Object();
  }, [dispatch, navigate, getAccessTokenSilently, uploadPdf]);

  // First useEffect: Fetch user data and set resumes
  useEffect(() => {
    if (data) {
      let userResumes = data.user.resumes.map((resume) => ({
        ...resume,
        content: JSON.parse(resume.content),
      }));
      dispatch(projectSlice.actions.setResumes(userResumes));
      dispatch(
        authSlice.actions.setHasActiveSubscription(
          data.user.has_active_subscription
        )
      );
    }
  }, [data, dispatch]);

  // Second useEffect: Set default and active resume when resumes are set
  useEffect(() => {
    const setDefaultAndActiveResume = async () => {
      if (resumes && resumes.length > 0) {
        const defaultResumeID = data.user.default_resume;

        let newDefaultResume;

        if (
          defaultResumeID !== REPRESENTS_UNSET_VALUES &&
          defaultResumeID !== null
        ) {
          newDefaultResume = resumes.find(
            (resume) => resume.id === defaultResumeID
          );
          if (newDefaultResume) {
            dispatch(
              projectSlice.actions.setDefaultResume(newDefaultResume)
            );
            dispatch(
              projectSlice.actions.setActiveResume(newDefaultResume)
            );
          } else {
            const firstResume = resumes[0];
            try {
              await setDefaultResume(firstResume.id).unwrap();
              dispatch(
                projectSlice.actions.setDefaultResume(firstResume)
              );
              dispatch(
                projectSlice.actions.setActiveResume(firstResume)
              );
            } catch (error) {
              console.error('Error setting default resume:', error);
              setError({ message: 'Failed to set default resume' });
            }
          }
        } else {
          // No default resume set, use the first one
          const firstResume = resumes[0];
          try {
            await setDefaultResume(firstResume.id).unwrap();
            dispatch(
              projectSlice.actions.setDefaultResume(firstResume)
            );
            dispatch(
              projectSlice.actions.setActiveResume(firstResume)
            );
          } catch (error) {
            console.error('Error setting default resume:', error);
            setError({ message: 'Failed to set default resume' });
          }
        }
      }
    };

    if (data && resumes) {
      setDefaultAndActiveResume();
    }
  }, [data, resumes, dispatch, setDefaultResume]);

  useEffect(() => {
    checkAndNavigateToProjectView();
  }, [checkAndNavigateToProjectView]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  if (loading) {
    return (
      <SpinnerLoader text="Preparing your resume" withBox={true} />
    );
  }

  return (
    <>
      {(isLoading ||
        setDefaultResumeIsLoading ||
        uploadPdfIsLoading) && <SpinnerLoader />}
      <div className="md:h-screen md:overflow-hidden bg-white dark:bg-black px-3 md:px-6 m-0">
        <DashboardHeader />
        <UserDashboard />
      </div>
      <div className="fixed right-0 bottom-8 mr-6">
        <ContactUs />
      </div>
      {error.message && <ErrorToast message={error.message} />}
    </>
  );
};

export default Dashboard;
