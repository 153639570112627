import React, { useEffect, useRef, useState } from 'react';
import Editor from '@toast-ui/editor';
import '@toast-ui/editor/dist/toastui-editor.css';

export default function ToastEditor({
  initialValue = '',
  height = '500px',
  initialEditType = 'wysiwyg',
  previewStyle = 'tab',
  onChange,
  hideModeSwitch = true, // This will hide the mode switch button
  toolbarItems,
  usageStatistics = false,
  onEditorInstance,
}) {
  const editorRef = useRef(null);
  const editorInstanceRef = useRef(null);
  const [currentMarkdown, setCurrentMarkdown] =
    useState(initialValue);

  useEffect(() => {
    // Initialize the editor once on mount
    editorInstanceRef.current = new Editor({
      el: editorRef.current,
      initialValue, // Set initial value only once
      height,
      initialEditType,
      previewStyle,
      hideModeSwitch, // Pass the hideModeSwitch prop to hide the Markdown tab
      usageStatistics,
      toolbarItems: toolbarItems || [
        ['heading', 'bold', 'italic', 'strike'],
        ['hr', 'quote'],
        ['ul', 'ol', 'task'],
        ['indent', 'outdent'],
        ['table', 'link'],
      ],
    });

    // Listen for changes to the editor content
    if (onChange) {
      editorInstanceRef.current.on('change', () => {
        const newMarkdown = editorInstanceRef.current.getMarkdown();

        // Only trigger onChange if the content has actually changed
        if (newMarkdown !== currentMarkdown) {
          setCurrentMarkdown(newMarkdown); // Update local state
          onChange(newMarkdown); // Trigger the parent's onChange callback
        }
      });
    }

    if (onEditorInstance) {
      onEditorInstance(editorInstanceRef.current);
    }

    // Cleanup the editor instance on component unmount
    return () => {
      editorInstanceRef.current.destroy();
    };
  }, []); // Empty dependency array to ensure it runs only once

  return <div className="border rounded-xl w-full" ref={editorRef} />;
}
